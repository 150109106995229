import React from "react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox-module";

import {SearchFilterLabel} from "./filters/SearchFilterLabel";

interface IOwnProps {
    error?: string[];
    name: string;
    label?: string;
    id: string;
    onAfterChange: (fieldName: string, value: number[]) => void;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
}

export const ConstructionEndDateSwitch = (props: IOwnProps) => {
    const onOptionSelect = (name: string) => {
        const selectedOptionValue = constructionEndDateOptions.filter((el) => el.label === name)[0].value;
        const valueSelected = props.value.includes(selectedOptionValue);

        if (!valueSelected) {
            props.onChange(props.name, [selectedOptionValue]);
        }

        if (valueSelected) {
            props.onChange(props.name, []);
        }
    };

    return (
        <>
            {props.label ? <SearchFilterLabel htmlFor={props.id} label={props.label} /> : null}
            <ConstructionEndDateHolder>
                {constructionEndDateOptions.map((option) => {
                    const check = props.value.includes(option.value);
                    return (
                        <Checkbox
                            id={"construction_date_option_" + option.value}
                            key={option.value}
                            labelContent={option.label}
                            name={option.label}
                            checked={check}
                            value={option.value}
                            onChange={onOptionSelect}
                        />
                    );
                })}
            </ConstructionEndDateHolder>
        </>
    );
};

//value based on month count
export const constructionEndDateOptions = [
    {label: "Gotowe do odbioru", value: 0},
    {label: "Do 3 miesięcy", value: 3},
    {label: "Do 6 miesięcy", value: 6},
    {label: "Do roku", value: 12},
    {label: "Do 3 lat", value: 36}
];

const ConstructionEndDateHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    flex-grow: 0;

    > div {
        margin-bottom: 1.5rem;
    }
`;
