import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {PropertyPlanIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";
import {areaFormat} from "@pg-mono/string-utils";

import {ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {OfferType} from "../../../offer/types/OfferType";
import {PropertyListApplicationButton} from "../../../property_list/components/property_list_application_button/PropertyListApplicationButton";
import {floorOrFloors} from "../../../property_list/PropertyListTableRow";
import {gtmEventPropertyListClick} from "../../../tracking/google_tag_manager/gtm_event_property_list";
import {IPropertySortOption} from "../../interfaces/IPropertySortOption";
import {IPropertyListProperty} from "../../types/PropertyListProperty";
import {cellA, cellB, cellC, cellD, cellE, PropertiesTableCell} from "./PropertiesTableCell";

type IProps = {
    property: IPropertyListProperty;
    index: number;
    offer: {
        id: number;
        vendor: {
            slug: string;
            id: number;
        };
        slug: string;
        configuration: {
            is_autoresponder_enabled: boolean;
        };
        type: OfferType;
    };
    handlePlanClick: () => void;
    sort: IPropertySortOption | null;
    sourceSection?: ApplicationSourceSection;
};

export function PropertiesTableRow(props: IProps) {
    const {property, index, offer, handlePlanClick, sort, sourceSection} = props;
    const colorVariant = index % 2 === 0 ? "light" : "dark";

    const responsiveLinkTarget = useResponsiveLinkTarget();

    const propertyLink = rpAppLink.property.detail.base({
        vendorSlug: offer.vendor.slug,
        offerSlug: offer.slug,
        offerId: offer.id,
        propertyId: property.id
    });

    const onClick = () => {
        gtmEventPropertyListClick("nieruchomosc");
    };

    return (
        <a href={propertyLink} onClick={onClick} target={responsiveLinkTarget}>
            <TableRow variant={colorVariant}>
                <PropertiesTableCell css={cellA}>
                    {property.plan_image_pages && <PropertyListPlanButton onPlanClick={handlePlanClick} index={index} />}
                </PropertiesTableCell>
                <PropertiesTableCell css={cellB} sorted={Boolean(sort && sort.fieldName === "rooms")}>
                    <Text variant="body_copy_2">{property.rooms}</Text>
                </PropertiesTableCell>
                <PropertiesTableCell css={cellC} sorted={Boolean(sort && sort.fieldName === "floor")}>
                    <Text variant="body_copy_2">{floorOrFloors(offer.type, property.floors, property.floor)}</Text>
                </PropertiesTableCell>
                <PropertiesTableCell css={cellD} sorted={Boolean(sort && sort.fieldName === "area")}>
                    <Text variant="body_copy_2">{areaFormat(property.area, {precision: 2})}</Text>
                </PropertiesTableCell>
                <PropertiesTableCell css={cellE} sorted={Boolean(sort && sort.fieldName === "price")}>
                    <PropertyListApplicationButton
                        property={property}
                        isAutoresponderEnabled={offer.configuration.is_autoresponder_enabled}
                        vendorId={offer.vendor.id}
                        applicationSourceSection={sourceSection ? sourceSection : undefined}
                    />
                </PropertiesTableCell>
            </TableRow>
        </a>
    );
}

/*
    Sub-components
 */
interface IPropertyListPlanButtonProps {
    onPlanClick: (e: React.MouseEvent<HTMLElement>, index: number) => void;
    index: number;
}

function PropertyListPlanButton(props: IPropertyListPlanButtonProps) {
    const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div onClick={stopPropagation}>
            {props.onPlanClick ? (
                <div>
                    <span onClick={(e) => props.onPlanClick(e, props.index)}>
                        <PropertyPlanIcon size="1.6" wrapperSize="1.6" wrapperColor="transparent" />
                    </span>
                </div>
            ) : (
                <a href="property.plan">
                    <PropertyPlanIcon size="1.6" wrapperSize="1.6" wrapperColor="transparent" />
                </a>
            )}
        </div>
    );
}

/*
    Styles
 */
const TableRow = styled.div<{variant?: "light" | "dark"}>`
    ${({theme, variant}) => css`
        ${variant &&
        css`
            background: ${variant === "light" ? theme.colors.gray[100] : theme.colors.gray[200]};
        `}
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;
